import React, {Fragment} from 'react';
import {Row, Col} from 'antd';
import Customer from '../Models/Customer';
import Field from './Field';

const labels = Customer.labels;

export default function CustomerData({record}) {
  return (
    <Fragment>
      {/* <Field label={labels['id']}>{record.id}</Field> */}

      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['name']}>{record.name}</Field>
        </Col>
        <Col span={8}>
          <Field label={labels['company_title']}>{record.company_title}</Field>
        </Col>
        <Col span={8}>
          <Field label={labels['uni_number']}>{record.uni_number}</Field>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['tel']}>{record.tel}</Field>
        </Col>
        <Col span={8}>
          <Field label={labels['fax']}>{record.fax}</Field>
        </Col>
      </Row>
      <Field label={labels['address']}>{record.address}</Field>

      <Field label={labels['invoice_address']}>{record.invoice_address}</Field>

      <Field label={labels['email']}>{record.email}</Field>

      <Field label={labels['pay_period']}>{record.pay_period} 天</Field>
      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['bank_name']}>{record.bank_name} </Field>
        </Col>
        <Col span={8}>
          <Field label={labels['bank_branch']}>{record.bank_branch} </Field>
        </Col>
        <Col span={8}>
          <Field label={labels['bank_account']}>{record.bank_account} </Field>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['contact_name1']}>{record.contact_name1} </Field>
        </Col>
        <Col span={8}>
          <Field label={labels['contact_phone1']}>
            {record.contact_phone1}{' '}
          </Field>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['contact_name2']}>{record.contact_name2} </Field>
        </Col>
        <Col span={8}>
          <Field label={labels['contact_phone2']}>
            {record.contact_phone2}{' '}
          </Field>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={8}>
          <Field label={labels['contact_name3']}>{record.contact_name3} </Field>
        </Col>
        <Col span={8}>
          <Field label={labels['contact_phone3']}>
            {record.contact_phone3}{' '}
          </Field>
        </Col>
      </Row>
    </Fragment>
  );
}
