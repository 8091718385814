import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Table, Button, Input} from 'antd';
import {Context} from '../../AppContext';
import Project from '../../Models/Project';
import {Link} from 'gatsby';
import ProjectForm from '../../Forms/ProjectForm';
import {date} from '../../Utils';

const LABELS = Project.labels;
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function ProjectListPage(props) {
  const {customer} = props; // customer = instance
  const app = useContext(Context);
  const [records, setRecords] = useState(null);
  const [filters, _setFilters] = useState({
    ...PAGINATION_INIT,
  });
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const columns = [
    // {
    //   title: LABELS['id'],

    // },

    {
      title: LABELS['name'],
      key: 'name',
      render: (record) => (
        <Link to={`/project/?id=${record.id}`}>{record.name}</Link>
      ),
    },
    {title: LABELS['owner_name'], dataIndex: 'owner_name', key: 'owner_name'},

    {
      title: LABELS['items'],
      render: (record) => <div>{record.items?.length || 0} 個</div>,
    },
    {
      title: LABELS['created'],
      render: (record) => <div>{date.format(record.created)} </div>,
    },
  ];

  const getRecords = useCallback(
    async (search = '') => {
      setLoading(true);
      try {
        const params = {
          query: {
            owner: customer.id,
            name: {$regex: search},
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ['-created'],
        };
        let resp = await app.actions.getProjects(params);
        setRecords(resp.results);
        setTotal(resp.total);
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    },
    [app.actions, filters, customer.id],
  );

  function setFilters(obj) {
    _setFilters((prev) => ({
      ...prev,
      ...PAGINATION_INIT,
      ...obj,
    }));
  }

  useEffect(() => {
    getRecords();

    return () => setRecords([]);
  }, [getRecords]);

  return (
    <Wrapper>
      <div className="action-bar">
        <Input
          value={search}
          placeholder="搜尋名稱"
          style={{width: 250}}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => getRecords(search)}>
          刷新
        </Button>
        <div style={{flex: 1}} />
        <Button
          type="primary"
          onClick={() => {
            app.actions.setModal(
              <ProjectForm
                record={
                  new Project({
                    owner: customer.id,
                    owner_name: customer.name,
                  })
                }
                onUpdate={() => getRecords(search)}
              />,
            );
          }}>
          ＋
        </Button>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={records}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onChange={(pagination) =>
          setFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .action-bar {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background-color: #fafafa;

    & > button {
      margin-left: 10px;
    }
  }
`;
