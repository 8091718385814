class Customer {
  constructor(record) {
    const {
      id = '',
      name = '',
      company_title = '', //
      uni_number = '', //
      tel = '', //
      fax = '',
      address = '', //
      invoice_address = '', //
      email = '',
      pay_period = 1, //以日為單位
      bank_name = '',
      bank_branch = '',
      bank_account = '',
      contact_name1 = '',
      contact_phone1 = '',
      contact_name2 = '',
      contact_phone2 = '',
      contact_name3 = '',
      contact_phone3 = '',
    } = (record = {});
    this.id = id;
    this.name = name;
    this.company_title = company_title;
    this.uni_number = uni_number;
    this.tel = tel;
    this.fax = fax;
    this.address = address;
    this.invoice_address = invoice_address;
    this.email = email;
    // this.pay_date = pay_date;
    this.pay_period = pay_period;
    this.bank_name = bank_name;
    this.bank_branch = bank_branch;
    this.bank_account = bank_account;
    this.contact_name1 = contact_name1;
    this.contact_phone1 = contact_phone1;
    this.contact_name2 = contact_name2;
    this.contact_phone2 = contact_phone2;
    this.contact_name3 = contact_name3;
    this.contact_phone3 = contact_phone3;
  }
}

function getColumns(_class) {
  if (!_class || typeof _class !== 'function' || !_class.labels) {
    throw new Error('Get columns error: not a class');
  }

  return Object.keys(_class.labels).map((key) => ({
    title: _class.labels[key],
    dataIndex: key,
    key: key,
  }));
}

Customer.labels = {
  id: '客戶編號',
  name: '客戶名稱',
  company_title: '抬頭',
  uni_number: '統編',
  tel: '公司電話',
  fax: '傳真',
  address: '送貨地址',
  invoice_address: '發票地址',
  email: '信箱',
  // pay_date: '付款日',
  pay_period: '付款票期',
  bank_name: '銀行',
  bank_branch: '分行',
  bank_account: '銀行帳號',
  contact_name1: '聯絡人',
  contact_phone1: '聯絡人手機',
  contact_name2: '聯絡人',
  contact_phone2: '聯絡人手機',
  contact_name3: '聯絡人',
  contact_phone3: '聯絡人手機',
};

module.exports = Customer;
