import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Context} from '../../AppContext';
import Customer from '../../Models/Customer';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit} from '@styled-icons/material';
import CustomerForm from '../../Forms/CustomerForm';
import ProjectList from './ProjectList';
import QuotationList from './QuotationList';
import {previousPage} from '../../Utils';
import CustomerData from '../../Components/CustomerData';
import CustomerBilling from '../../Components/BillingTable/CustomerBilling';
const queryString = require('query-string');

const labels = Customer.labels;

const dbg = {
  id: 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function CustomerDetailPage(props) {
  const {id = dbg.id} = queryString.parse(props.location.search); //order.id
  const app = useContext(Context);
  const [record, setRecord] = useState();

  const getRecord = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getCustomer({query: {id}});
      setRecord(resp);
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage(`/customers`)}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />
      </div>
      <Row gutter={[30, 30]}>
        {record && (
          <Col span={24}>
            <Block
              title="顧客資訊"
              actions={[
                {
                  icon: <Edit size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModalWidth(900);
                    app.actions.setModal(
                      <CustomerForm record={record} onUpdate={getRecord} />,
                    );
                  },
                },
              ]}>
              <CustomerData record={record} />
            </Block>
          </Col>
        )}
        <Col span={12}></Col>
      </Row>

      <Row gutter={[30, 30]}>
        {record && (
          <Col span={24}>
            <Block title="專案列表" contentStyle={{padding: 0}}>
              <ProjectList customer={record} />
            </Block>
          </Col>
        )}
        {record && (
          <Col span={24}>
            <Block title="報價單列表" contentStyle={{padding: 0}}>
              <QuotationList customer={record} />
            </Block>
          </Col>
        )}
        {record && (
          <Col span={24}>
            <Block title="客戶帳務列表" contentStyle={{padding: 0}}>
              <CustomerBilling customer={record} />
            </Block>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
