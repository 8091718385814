import React, {useContext, useEffect} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import {Button} from '../Widgets';
import Customer from '../Models/Customer';
import formatValidator from '../Utils/formatValidator';
import {ErrCustomer, errorHandler} from '../errors';
import {message, Row, Col} from 'antd';

const {isNotEmpty, isMobileNumber, isNumeric} = formatValidator;

const LABELS = Customer.labels;

export default function CustomerForm({record: _record, onUpdate}) {
  const {renderInput, renderText, record} = useForm({record: _record});
  const app = useContext(Context);
  const edit = !!record.id;

  const valid = () => {
    if (
      !isNotEmpty(record.contact_name1) ||
      !isNotEmpty(record.contact_phone1)
    ) {
      throw new ErrCustomer('至少填入一位聯絡人，聯絡人、聯絡手機不能為空');
    }
    if (!isMobileNumber(record.contact_phone1)) {
      throw new ErrCustomer('聯絡手機格式錯誤');
    }

    if (!isNumeric(record.pay_period)) {
      throw new ErrCustomer('付款票期須為數字');
    }
  };

  const submit = async () => {
    const action = edit ? '編輯' : '新增';
    app.actions.setModalLoading(true);
    try {
      valid(record);

      const params = {
        query: edit ? {id: record.id} : {},
        data: record,
      };
      if (edit) {
        await app.actions.editCustomer(params);
      } else {
        await app.actions.addCustomer(params);
      }

      onUpdate();
      app.actions.setModal();

      message.success(action + '成功');
    } catch (ex) {
      errorHandler(ex, action + '錯誤');
    }
    app.actions.setModalLoading(false);
  };

  return (
    <Wrapper>
      <h3>客戶資訊</h3>
      <div className="content">
        {/* {renderText(LABELS['id'], 'id')} */}
        <Row gutter={15}>
          <Col span={8}>{renderInput(LABELS['name'], 'name')}</Col>
          <Col span={8}>
            {renderInput(LABELS['company_title'], 'company_title')}
          </Col>
          <Col span={8}>{renderInput(LABELS['uni_number'], 'uni_number')}</Col>
          <Col span={8}>{renderInput(LABELS['tel'], 'tel')}</Col>
          <Col span={8}>{renderInput(LABELS['fax'], 'fax')}</Col>
          <Col span={8}></Col>

          <Col span={24}>{renderInput(LABELS['address'], 'address')}</Col>
          <Col span={24}>
            {renderInput(LABELS['invoice_address'], 'invoice_address')}
          </Col>
          <Col span={24}>{renderInput(LABELS['email'], 'email')}</Col>

          <Col span={8}>
            {renderInput(LABELS['pay_period'], 'pay_period', {
              extraProps: {placeholder: '天數', type: 'number'},
            })}
          </Col>
          <Col span={16}></Col>

          <Col span={8}>{renderInput(LABELS['bank_name'], 'bank_name')}</Col>
          <Col span={8}>
            {renderInput(LABELS['bank_branch'], 'bank_branch')}
          </Col>
          <Col span={8}>
            {renderInput(LABELS['bank_account'], 'bank_account')}
          </Col>
          <Col span={8}>
            {renderInput(LABELS['contact_name1'], 'contact_name1')}
          </Col>
          <Col span={8}>
            {renderInput(LABELS['contact_phone1'], 'contact_phone1')}
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            {renderInput(LABELS['contact_name2'], 'contact_name2')}
          </Col>
          <Col span={8}>
            {renderInput(LABELS['contact_phone2'], 'contact_phone2')}
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            {renderInput(LABELS['contact_name3'], 'contact_name3')}
          </Col>
          <Col span={8}>
            {renderInput(LABELS['contact_phone3'], 'contact_phone3')}
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
      <div className="footer">
        <Button onClick={submit}>確認</Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    padding: 20px 30px;
  }

  & > .content {
    flex: 1;
    padding: 30px;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding: 20px 30px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
